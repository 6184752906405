.fkMobileHome {
  position: relative;
  padding-top: 15vw;
}

.fkMobileHome img {
  position: absolute;
}

.fkMobileHome img:first-of-type {
  width: 84.8vw;
  z-index: 1;
  top: 24vw;
  left: 7.6vw;
}

.fkMobileHome img:nth-child(2) {
  width: 62.84444vw;
  right: -5vw;
}

.fkMobileHome img:last-of-type {
  width: 61.2333vw;
  left: -5vw;
}