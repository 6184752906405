.carouselIndex img {
  cursor: pointer;
}

.carouselIndex img:not(:last-child) {
  margin-right: 24px;
}

.carouselFK {
  position: relative;
}

.carouselFK img {
  position: absolute;
}

.carouselFK img:last-of-type {
  z-index: 1;
}

.carouselIndex {
  position: absolute;
  top: 28vw;
  left: 85vw;
  width: 100%;
  z-index: 99;
}

.carouselIndex img {
  width: 48.5px;
  height: 48.5px;
  margin-top: 12px;
}
.learnMore {
  padding: 4px 26px 0 26px;
  letter-spacing: 0px;
  color: #000000;
  background: #fff;
  border-radius: 12px;
  font-size: 22px;
  line-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: 'Heading Font Name';
  margin-top: 12px;
  cursor: pointer;
}

.learnMore:hover {
  background: #00f2ff;
}