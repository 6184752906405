.MobileWeb3imgBox img {
  position: absolute;
}

.MobileWeb3imgBox img:first-of-type {
  z-index: 3;
}

.MobileWeb3imgBox img:nth-child(2) {
  z-index: 3;
}

.MobileWeb3imgBox img:nth-child(3) {
  z-index: 3;
}

.MobileWeb3imgBox img:nth-child(4) {
  z-index: 5;
}

.MobileWeb3imgBox img:nth-child(5) {
  z-index: 1;
}

.MobileWeb3imgBox img:nth-child(6) {
  z-index: 4;
}

.MobileWeb3imgBox img:nth-child(7) {
  z-index: 5;
}

.MobileWeb3imgBox img:nth-child(8) {
  z-index: 2;
}

.MobileWeb3imgBox img:nth-child(9) {
  z-index: 1;
}

.MobileWeb3imgBox img:nth-child(10) {
  z-index: 8;
}

.Web3imgBox img:nth-child(11) {
  z-index: 7;
}

.MobileWeb3imgBox img:nth-child(12) {
  z-index: 2;
}

.MobileWeb3imgBox img:last-of-type {
  z-index: 3;
}