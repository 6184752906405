.container {
  /* padding: 0 2rem; */
}

.main {
  overflow-x: hidden;
  /* padding: 4rem 0; */
  /* flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.footer {
  background-color: #ffffff;
  display: flex;
  flex: 1;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

.carouselIndex {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 24px;
  left: calc((100vw - 380px) / 2);
  width: 380px;
  margin: 0 auto;
}

.carouselIndex img {
  cursor: pointer;
}

.carouselIndex img:not(:last-child) {
  margin-right: 24px;
}

.fkHome,
.carouselFK {
  position: relative;
}

.fkHome img,
.carouselFK img {
  position: absolute;
}

.carouselFK img:last-of-type {
  left: 26vw;
  top: 100px;
  z-index: 1;
}

.fkHome img:first-of-type {
  left: calc(50vw - 200px);
  z-index: 1;
  top: 10vw;
}

.fkHome img:nth-child(2) {
  left: calc(50vw);
  top: 2vw;
}

.fkHome img:last-of-type {
  left: calc(50vw - 400px);
  top: 2vw;
}

.Web3imgBox img {
  position: absolute;
}

.Web3imgBox img:first-of-type {
  left: 30.04843vw;
  z-index: 3;
}

.Web3imgBox img:nth-child(2) {
  top: 435.55px;
  z-index: 3;
}

.Web3imgBox img:nth-child(3) {
  z-index: 3;
}

.Web3imgBox img:nth-child(4) {
  top: 430px;
  z-index: 5;
}

.Web3imgBox img:nth-child(5) {
  left: 64.5734375vw;
  z-index: 1;
}

.Web3imgBox img:nth-child(6) {
  left: 42.48125vw;
  z-index: 4;
}

.Web3imgBox img:nth-child(7) {
  top: 457.23px;
  z-index: 5;
}

.Web3imgBox img:nth-child(8) {
  z-index: 2;
}

.Web3imgBox img:nth-child(9) {
  z-index: 1;
}

.Web3imgBox img:nth-child(10) {
  left: 46.875vw;
  z-index: 8;
}

.Web3imgBox img:nth-child(11) {
  left: 37.08vw;
  z-index: 7;
}

.Web3imgBox img:nth-child(12) {
  left: 60.96875vw;
  z-index: 2;
}

.Web3imgBox img:last-of-type {
  left: 60.96875vw;
  left: 61.6vw;
  top: 460px;
  z-index: 3;
}

.learnMore {
  padding: 4px 26px 0 26px;
  letter-spacing: 0px;
  color: #000000;
  background: #fff;
  border-radius: 12px;
  font-size: 22px;
  line-height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: "Heading Font Name";
  margin-top: 12px;
  cursor: pointer;
  max-width: 275px;
}

.learnMore:hover {
  background: #00f2ff;
}

.mainBox .mobileGrid {
  display: none !important;
}

@media (max-width: 1600px) {
  .differentEntryHed {
    font-size: 32px !important;
    line-height: 50px !important;
    /* transform: scale(0.7); */
  }
}

@media (max-width: 1480px) {
  .differentEntryText {
    transform: scale(0.8);
  }
}

@media (max-width: 1400px) {
  .differentEntryText {
    width: 400px !important;
    bottom: 85px !important;
    /* transform: scale(0.75); */
  }
}

@media only screen and (max-width: 1026px) {
  .footer {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

@media (max-width: 1700px) {
  .pkTextBox {
    bottom: 300px !important;
  }
}

@media (max-width: 1350px) {
  .pkTextBox {
    bottom: 210px !important;
  }
}

@media (max-width: 1024px) {
  .differentEntryText {
    width: 100% !important;
    top: -20px !important;
    left: 0 !important;
    padding: 0 32px !important;
  }

  .fkHome img:first-of-type {
    top: 42vw;
  }

  .fkHome img:nth-child(2) {
    top: 32vw;
  }

  .fkHome img:last-of-type {
    top: 32vw;
  }
}

@media (max-width: 768px) {
  .mainBox .mobileGrid {
    display: grid !important;
  }

  .mainBox .pcGrid {
    display: none !important;
  }
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (min-width: 1920px) {
  .fkHome img:first-of-type {
    left: 33vw;
    top: 8vw;
  }
  
  .fkHome img:nth-child(2) {
    left: 50vw;
  }
  
  .fkHome img:last-of-type {
    left: 18vw;
  }
}